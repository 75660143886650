import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import emailjs from 'emailjs-com';
import {withTranslation} from "react-i18next";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js"; 


class Customer extends React.Component {
  state = {
    defaultModal: false
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bfekcbd', 'template_daeyn8e', e.target, 'iboGqyaqrpaTq0wcG')
      .then((result) => {
          console.log(result.text);
          this.toggleModal("defaultModal");
      }, (error) => {
          console.log(error.text);
      });
  }

  render() {
    const {t} = this.props;

    return (
      <>
        <Modal
          scrollable={true}
          className="modal-dialog-centered"
          isOpen={this.state.defaultModal}
          toggle={() => this.toggleModal("defaultModal")}
        >
          <div className="modal-header align-items-center">
            <h6 className="modal-title" id="modal-title-default">
              Your message sent.
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div> 
        </Modal>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-customer-cover section-shaped my-0" > 
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us " style={{ background: "#f4f5f7" , paddingBottom: "0 "}}>
            <Container> 
              <Row className="justify-content-center" >
                <Col>
                  <Card className="bg-gradient-secondary shadow-sm mt--100">
                    <CardBody className="p-lg-5" >
                      <form onSubmit={this.sendEmail}> 
                        <h4 className="mb-1">{t('Customer Title')}</h4>
                        <p className="mt-0">
                          {t('Customer Detail')}
                        </p>
                        <FormGroup
                          className={classnames("mt-5", {
                            focused: this.state.nameFocused
                          })}
                        > 
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-user-run" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={t('Customer Name')}
                              name="from_name"
                              type="text"
                              onFocus={(e) =>
                                this.setState({ nameFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ nameFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.emailFocused
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={t('Customer Email')}
                              name="email_id"
                              type="email"
                              onFocus={(e) =>
                                this.setState({ emailFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ emailFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Input
                            className="form-control-alternative"
                            cols="80"
                            name="message"
                            placeholder={t('Customer Message')} 
                            rows="4"
                            type="textarea"
                          />
                        </FormGroup>
                        <div>
                          <Button
                            block
                            className="btn-round"
                            color="default"
                            size="lg"
                            //type="button"
                            type="submit" 
                          >
                            {t('Customer Send')} 
                          </Button>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withTranslation()(Customer);
