import React from "react";
import {withTranslation} from "react-i18next";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Vision extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    const {t} = this.props;

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main" style={{ whiteSpace: "pre-line"}}>
          <section className="section-vision-cover section-shaped my-0">
            <div style={{ position: "absolute", top: "0", bottom: "0", zindex: "1", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", color: "white" }}>
              {t('Vision Slogan')} 
            </div>
          </section>
          <section className="section" style={{ background: "#f4f5f7" }}>
            <Container>
              <Card className="card-profile shadow-sm mt--100">
                <div className="px-4"> 
                  <div className="mt-5">
                    <h4 className="font-weight-bold">
                      {t('Vision Title')} 
                    </h4> 
                  </div>
                  <div className="mt-5 py-5 border-top"> 
                    <Row className="row-grid align-items-center justify-content-center"> 
                      <Col className="order-md-1">
                        <div className="md-30"> 
                          <h4>{t('Vision Title1')}</h4>
                          <p>
                            {t('Vision Detail1')}
                          </p> 
                        </div>
                        <div className="my-sm-n-6"> 
                          <h4>{t('Vision Title2')}</h4>
                          <p>
                            {t('Vision Detail2')}
                          </p> 
                        </div>
                        <div className="my-sm-n-6"> 
                          <h4>{t('Vision Title3')}</h4>
                          <p>
                            {t('Vision Detail3')}
                          </p> 
                        </div>
                        <div className="my-sm-n-6"> 
                          <h4>{t('Vision Title4')}</h4>
                          <p>
                            {t('Vision Detail4')}
                          </p> 
                        </div>
                        <div> 
                          <h4>{t('Vision Title5')}</h4>
                          <p>
                            {t('Vision Detail5')}
                          </p> 
                        </div>
                      </Col>
                    </Row>   
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withTranslation()(Vision);
