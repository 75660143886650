import React from "react";
import {withTranslation} from "react-i18next";
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class AboutUs extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    const {t} = this.props; 

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-aboutus-cover section-shaped my-0"> 
          </section>
          <section className="section" style={{ background: "#f4f5f7" }}>
            <Container>
              <Card className="card-profile shadow-sm mt--100" style={{ whiteSpace: "pre-line"}}>
                <div className="px-4"> 
                  <div className="mt-5">
                    <h4 className="font-weight-bold">
                       {t('AboutUs Hello')} 
                    </h4> 
                  </div>
                  <div className="mt-5 py-5 border-top"> 
                    <Row className="row-grid align-items-center justify-content-center"> 
                      <Col className="order-md-1">
                        <div className="md-30">  
                          <p>
                            {t('AboutUs Detail')} 
                          </p> 
                        </div> 
                        <div className="mt-7"> 
                          <p>
                            {t('AboutUs Thank')}
                          </p> 
                        </div>
                      </Col>
                    </Row>   
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withTranslation()(AboutUs); 
