import React from "react";
import {withTranslation} from "react-i18next";
// reactstrap components
import {
  Button, 
  Modal,
  Row,
  Col
} from "reactstrap";


class Privacy extends React.Component {
  state = {
    defaultModal: false
  };
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  render() {
    const {t} = this.props; 
    return (
      <>
        <Row>
          <Col>
            <div onClick={() => this.toggleModal("defaultModal")} >
            {t('Privacy Policy')}
            </div>
            <Modal
              scrollable={true}
              className="modal-dialog-centered"
              isOpen={this.state.defaultModal}
              toggle={() => this.toggleModal("defaultModal")}
            >
              <div className="modal-header align-items-center">
                <h6 className="modal-title" id="modal-title-default">
                  Privacy Policy
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p style={{ fontSize:"0.8rem"}} className="font-weight-light">
In order to protect the user’s personal information and rights in accordance with the Personal Information Protection Act and to address user complaints related to personal information, ShinilPot (hereunder “company”) applies the policy it established as described below.
“company” notifies updates of the Privacy Policy, if any, through the noticeboard of its website (or personal notification).
<br />
<br />
This policy is effective as of January 1, 2023.
<br />
<br />
1. Purpose of Personal Information Processing “company” utilizes the personal information collected for the purposes described below. Personal information that is handled is not used for purposes other than those stated below. If there is any change to the purpose of use, prior consent will be sought.
<br />A. Website membership management
Identification• authentication for service provision, membership maintenance, identification in accordance with the limited verification of identity system, and prevention of illegal use of service.
<br />B. Handling of civil applications
Personal information may be used for the purpose of identifying applicants, checking application details, contacting for fact checking, and notifying the results.
<br />C. Provision of materials or services
Personal information may be used for service provision purposes.
<br />D. Use for marketing and advertisement
Personal information may be used for the purpose of checking of validity of services, grasping of access frequency, and statistical reference of members’ service use.
<br />
<br />
2. Present status of personal information files
<br />• 1. Personal information file name: Personal information
<br />• Personal information items: E-mail, mobile phone no., company telephone no., title, department, company name, access log, access IP info., and legal representative’s name
<br />• How to collect info.: Website
<br />• Reason for retention: Agreement to personal information use
<br />• Retention period: 3
<br />• Related laws: Records regarding the collection, processing, and use of credit information: 3 years, Records of consumer complaints or dispute settlements: 3 years, Records of contracts or application withdrawal: 5
<br />
<br />
3. Period of personal information processing and retention① “company” processes and retains personal information during the designated period of use as agreed upon with the information object and according to laws concerning the retention and use period of personal information.
② The period of personal information processing and retention depending on the service type is as follows:
<br />• 1.Civic service
<br />• Personal information related to civic service provision is retained and used for the aforesaid purposes for 3 years from the date of agreement to the collection and use of personal information.
<br />• Reason for retention: Agreement to personal information use
<br />• Related laws: 1) Records of consumer complaints or dispute settlements: 3 years, 2) Records of contracts or application withdrawal: 5
<br />• Reason for exception:
<br />
<br />
4. Provision of personal information to a third party① “company” may provide a third party with personal information only when the personal information object agrees to it and such is in accordance with specific laws or regulations such as Articles 17 and 18 of the Personal Information Protection Act.
② “company” may provide personal information to a third party in the following cases:
<br />• 1. “company”
<br />• Those who are provided with personal information: “company”
<br />• Purpose of the receiver’s use of personal information: E-mail, mobile phone no., company telephone no., title, department, company name, and legal representative’s name
<br />• Purpose of the receiver’s retention and use of personal information: 3
<br />
<br />
5. Rights and duties of the information object and legal representative as well as how to exercise the rights As a personal information object, a user may exercise the following rights:
<br />① An information object may exercise the right to request “company” for opening, correction, deletion, and suspension of use of personal information anytime.
<br />② The act of exercising the rights stated in Paragraph 1 above may be implemented in writing or via e-mail or fax in accordance with Article 41-1 of the Enforcement Ordinance of the Personal Information Protection Act. In response, “company” will take measures accordingly without delay.
<br />③ An information object may exercise the rights stated in Paragraph 1 above through a legal representative or an entrusted individual. In this case, the user shall submit a letter of attorney using Annex Form No. 11 of the Enforcement Regulations of the Personal Information Protection Act.
<br />④ The information object’s right to request opening or suspension of use of personal information may be restricted in accordance with Articles 35-5 and 37-2 of the Personal Information Protection Act.
<br />⑤ As for the correction and deletion of certain personal information items, in case the relevant law specifies such items as a basic requirement, the request for deletion may not be accepted.
<br />⑥ As for the correction and deletion of certain personal information items, in case the relevant law specifies such items as a basic requirement, the request for deletion may not be accepted.
<br />
<br />
6. Personal information items to be handled① “company” handles the following personal information items:
<br />• 1 Civic service
<br />• Required items: E-mail, mobile phone no., company telephone no., title, department, company name, and legal representative’s name and mobile phone no.
<br />• Optional items:
<br />
<br />
7. Disposal of Personal Information Once the purpose of handling personal information is fulfilled, “company” destroys the personal information without delay, in principle. Personal information is deleted using the following procedure and method:
<br />-Deletion Procedures
<br />Information that is entered by a user is transferred to a separate database once its purpose is fulfilled (in the case of paper, a separate filing cabinet), and then destroyed after the designated period in accordance with internal directions or related laws on privacy protection. Personal information transferred to a DB is not used for any other purpose unless required by law.-Information DisposalA user’s personal information is destroyed within 5 days of the final date of retention if the retention period expires and within 5 days of the date when the handling of personal information is judged as unnecessary for reasons such as fulfillment of handling personal information, termination of service, completion of business, etc.
<br />-Disposal Method
<br />Personal information saved in electronic file format is deleted by means of a technical method that makes opening the file impossible.
<br />
<br />
8. Installation, operation, and denial of devices automatically collecting personal information
“company” does not use “cookies” that save and frequently load an information object’s use logs.
<br />
<br />
9. Person in charge of protecting personal information
<br />① In order to manage duties regarding personal information as well as handle complaints and remedies, “company” designates the following person in charge of protecting personal information:
<br />• Person in charge of protecting personal information
<br />• Name: 
<br />• Title: 
<br />• Position: 
<br />• Contact Info.: 
<br />② An information object may inquire with the personal information protection manager and responsible department about personal information protection, unsatisfactory service, remedies for damage, etc. regarding the service (business) offered by “company” “company” will provide an answer to such inquiries without delay.
<br />
<br />
10. Change to privacy policy
①The present privacy policy comes into effect on the enforcement date. When there is any addition, deletion, or correction of a provision according to related laws or regulations, such shall be notified at least 7 days before its application.
<br />
<br />
11. Measures for personal information security According to Article 29 of the Personal Information Protection Act, “company” takes technical, managerial, and physical measures for information security as follows:
<br />1. “company” designates the minimal number of employees in charge of handling personal information so that personal information is handled only by the designated individual(s) in charge.
<br />2. Technical measures for hacking, etc.
In order to prevent personal information leak or damage due to hacking, computer viruses, etc., “company” installs and regularly updates and checks security programs. “company” installs a security system in a restricted area and supervises it technically and physically.
<br />3. Personal information encryption
<br />A user’s password is encrypted when stored and managed and is known to the user only. For files including vital data, additional security functions such as encryption and file locking are applied.
<br />4. Retention of access logs and forgery prevention
Logs of access to the personal information processing system are kept and managed for at least 6 months. Security functions are applied to prevent log forgery, leak, or loss.
<br />5. Control of unauthorized individuals’ access
Personal information is kept in a physically separate place, and access control procedures are established and operated.
</p>
              </div>
              <div className="modal-footer"> 
                <Button
                  className="ml-auto"
                  color="default"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </Col>  

        </Row>

      </>
    );
  }
}

export default withTranslation()(Privacy); 