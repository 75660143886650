import React from "react";
import { Link } from "react-router-dom"; 
import Headroom from "headroom.js";
import {withTranslation} from "react-i18next";
import i18next from "../../languages/i18n";

// reactstrap components
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";



class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  }; 
  
  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };  

  changeLanguage = (e) => {
        let lan = e.target.innerText.toLowerCase();
        i18next.changeLanguage(lan === 'ko' ? 'en' : 'ko');
        this.setState({
            "languageTitle": lan === 'ko' ? 'en' : 'ko'
        })
      
  }

  render() { 
    const {t, i18n} = this.props; 
    
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container> 
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/ShinilpotLogoWhite.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/ShinilpotLogoBlk.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div> 

                <Nav className="navbar-nav-hover align-items-lg-center justify-content-lg-center" navbar style= {{ width: "100%" }}>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <span className="nav-link-inner--text">{t('Company')}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/aboutus-page" tag={Link}>
                        {t('About Us')}
                      </DropdownItem>
                      <DropdownItem to="/history-page" tag={Link}>
                        {t('History')}
                      </DropdownItem>
                      <DropdownItem to="/vision-page" tag={Link}>
                        {t('Vision')}
                      </DropdownItem> 
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  


                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/product1-page" tag={Link}>
                      <span className="nav-link-inner--text">{t('Product')}</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>


                  {/* <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <span className="nav-link-inner--text">{t('R&D')}</span>
                    </DropdownToggle>
                  </UncontrolledDropdown> */}

                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/customer-page" tag={Link}>
                      <span className="nav-link-inner--text">{t('Customer')}</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>  
                </Nav>  
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      id="tooltip333589076"
                      target="_blank"
                    >
                    <Button outline size="sm"  color="white"  onClick={this.changeLanguage} >
                      {i18n.language}
                    </Button>
                      
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589076">
                      Language
                    </UncontrolledTooltip>
                  </NavItem> 
                </Nav> 

                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.youtube.com/@shin-iltogi9354"
                      id="tooltip333589075"
                      target="_blank"
                    >
                      <i className="fa fa-youtube" />
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589075">
                      Youtube
                    </UncontrolledTooltip>
                  </NavItem> 
                </Nav> 

                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="http://gangnampot.com/"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-shopping-bag" />
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Online Shop
                    </UncontrolledTooltip> 
                  </NavItem> 
                </Nav> 
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default withTranslation()(DemoNavbar);