import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
 
import Customer from "views/examples/Customer.js";

import Index from "views/Index.js";
import AboutUs from "views/examples/AboutUs.js";
import History from "views/examples/History.js";
import Vision from "views/examples/Vision.js";
import Product1 from "views/examples/Product1.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <Route
        path="/aboutus-page"
        exact
        render={(props) => <AboutUs {...props} />}
      />
      <Route
        path="/history-page"
        exact
        render={(props) => <History {...props} />}
      />
      <Route
        path="/vision-page"
        exact
        render={(props) => <Vision {...props} />}
      />
      <Route
        path="/product1-page"
        exact
        render={(props) => <Product1 {...props} />}
      />
      <Route
        path="/customer-page"
        exact
        render={(props) => <Customer {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);
