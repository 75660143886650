import React from "react"; 
// reactstrap components
import { Card, Container } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Product1 extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() { 
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-product1-cover section-shaped my-0"> 
          </section>
          <section className="section" style={{ background: "#f4f5f7" }}>
            <Container>
              <Card className="card-profile shadow-sm mt--100" style={{ whiteSpace: "pre-line"}}>
                {/* <div className="px-4"> 
                  <div className="mt-5">
                    <h4 className="font-weight-bold">
                       {t('Product1 Title')} 
                    </h4> 
                  </div>
                  <div className="mt-5 "> 
                  </div>
                </div> */}
                {/* 이미지 */}
                <div style={{ width: "100%", margin: "0", position: "relative"}}>  
                  <img style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/theme/product_detail_1.png")}
                  />
                  <img style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/theme/product_detail_2.png")}
                  />
                  <img style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/theme/product_detail_3.png")}
                  />
                  <img style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/theme/product_detail_4.png")}
                  />
                  <img style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/theme/product_detail_5.png")}
                  />
                  <img style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/theme/product_detail_6.png")}
                  />
                </div>   
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Product1; 
