import React from "react";
import {withTranslation} from "react-i18next";

import BgVideo from "../../assets/video/shilinpotvideo.mp4" 

import {  Container, Row, Col } from "reactstrap";

class Hero extends React.Component { 
  render() {
    const {t} = this.props;
    
    return (
      <>
        <div className="position-relative"> 
          <section className="section-hero"  style={{ whiteSpace: "pre-line"}}> 
            <div className="shape">
              <video 
                className="video"
                src={BgVideo}
                loop
                muted
                autoPlay
              /> 
              <div style={{ position: "absolute", top: "0", bottom: "0", zindex: "1", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", color: "white" }}>
                <h3 className="text-white font-weight-bold" style={{ letterSpacing: "-0.03rem" }}>
                  {t('Main Slogan')} 
                </h3>
              </div>
            </div> 
          </section>

          <section className="section section-lg" style={{ background : "#f4f5f7"}} >
            <Container>
              <Row className="row-grid align-items-center">
              <Col className="order-md-1" md="6">
                  <div className="pr-md-5"> 
                    <h5 className="font-weight-bold">{t('Main Title1')} </h5>
                    <p>
                      {t('Main Detail1')} 
                    </p> 
                  </div>
                </Col>
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    style={{ borderRadius: "1rem" }}
                    src={require("assets/img/theme/main_bg1.png")}
                  />
                </Col>
                
              </Row>
            </Container>
          </section>

          <section className="section section-lg" style={{ background : "#f4f5f7"}} >
            <Container>
              <Row className="row-grid align-items-center"> 
                <Col className="order-md-2" md="6">
                  <div className="pr-md-5"> 
                    <h5 className="font-weight-bold">{t('Main Title2')} </h5>
                    <p>
                      {t('Main Detail2')} 
                    </p> 
                  </div>
                </Col>
                <Col className="order-md-1" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    style={{ borderRadius: "1rem" }}
                    src={require("assets/img/theme/main_bg2.png")}
                  />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg" style={{ background : "#f4f5f7"}} >
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5"> 
                    <h5 className="font-weight-bold">{t('Main Title3')} </h5>
                    <p>
                      {t('Main Detail3')} 
                    </p> 
                  </div>
                </Col>
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    style={{ borderRadius: "1rem" }}
                    src={require("assets/img/theme/main_bg3.png")}
                  />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg" style={{ background : "#f4f5f7"}} >
            <Container>
              <Row className="row-grid align-items-center"> 
                <Col className="order-md-2" md="6">
                  <div className="pr-md-5"> 
                    <h5 className="font-weight-bold">{t('Main Title4')} </h5>
                    <p>
                      {t('Main Detail4')} 
                    </p> 
                  </div>
                </Col>
                <Col className="order-md-1" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    style={{ borderRadius: "1rem" }}
                    src={require("assets/img/theme/main_bg4.png")}
                  />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg" style={{ background : "#f4f5f7"}} >
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5"> 
                    <h5 className="font-weight-bold">{t('Main Title5')} </h5>
                    <p>
                      {t('Main Detail5')} 
                    </p> 
                  </div>
                </Col>
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    style={{ borderRadius: "1rem" }}
                    src={require("assets/img/theme/main_bg5.png")}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </>
    );
  }
}

export default withTranslation()(Hero);