import React from "react";
import {withTranslation} from "react-i18next";

// reactstrap components
import { Card, Container } from "reactstrap";
import { Timeline, Event } from "react-timeline-scribble";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class History extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    const {t} = this.props;

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main" style={{ whiteSpace: "pre-line"}}>
          <section className="section-history-cover section-shaped my-0">
          </section>
          <section className="section" style={{ background: "#f4f5f7" }}>
            <Container>
              <Card className="card-profile shadow-sm mt--100">
                <div className="px-4"> 
                  <div className="mt-5">
                    <h4 className="font-weight-bold">
                      {t('History Title')} 
                    </h4> 
                  </div>
                  <div className="mt-5 py-5 border-top">
                    <Timeline>
                      <Event interval={"1953.03"} subtitle={ t('History Title1') }>
                        {t('History Detail1')}
                      </Event>
                      <Event interval={"1975.07"} subtitle={ t('History Title2') }>
                        {t('History Detail2')}
                      </Event>
                      <Event interval={"1980.10"} subtitle={ t('History Title3') }>
                        {t('History Detail3')}
                      </Event>
                      <Event interval={"2023.04"} subtitle={ t('History Title4') }>
                        {t('History Detail4')}
                      </Event>
                    </Timeline> 
                  </div> 
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withTranslation()(History);
