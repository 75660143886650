import React from "react";
// reactstrap components
import { Container, Row, Col, NavItem, NavLink, Nav } from "reactstrap";

import Privacy from "../../views/examples/Privacy.js";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <Container>
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                  Shinil Pot All Right Reserved
                </div>
              </Col> 
              <Col md="6">
                <Nav className="nav-footer justify-content-end">
                  <NavItem>
                    <NavLink href="#" target="_blank"> 
                      <Privacy />
                    </NavLink>
                  </NavItem> 
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter; 
